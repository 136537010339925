import { defineStore } from 'pinia'
import type { CookieRef } from 'nuxt/app'
import utmSourceSchema from '~/utils/schemas/utmSourceSchema'

export const useGlobalStore = defineStore('global', {
    state: (): State => ({
        clientIp: null,
        formSuccessModalOpen: false,
        formSuccessModalMessage: null,
        states: {
            AL: 'Alabama',
            AK: 'Alaska',
            AZ: 'Arizona',
            AR: 'Arkansas',
            CA: 'California',
            CO: 'Colorado',
            CT: 'Connecticut',
            DE: 'Delaware',
            DC: 'District Of Columbia',
            FL: 'Florida',
            GA: 'Georgia',
            HI: 'Hawaii',
            ID: 'Idaho',
            IL: 'Illinois',
            IN: 'Indiana',
            IA: 'Iowa',
            KS: 'Kansas',
            KY: 'Kentucky',
            LA: 'Louisiana',
            ME: 'Maine',
            MD: 'Maryland',
            MA: 'Massachusetts',
            MI: 'Michigan',
            MN: 'Minnesota',
            MS: 'Mississippi',
            MO: 'Missouri',
            MT: 'Montana',
            NE: 'Nebraska',
            NV: 'Nevada',
            NH: 'New Hampshire',
            NJ: 'New Jersey',
            NM: 'New Mexico',
            NY: 'New York',
            NC: 'North Carolina',
            ND: 'North Dakota',
            OH: 'Ohio',
            OK: 'Oklahoma',
            OR: 'Oregon',
            PA: 'Pennsylvania',
            RI: 'Rhode Island',
            SC: 'South Carolina',
            SD: 'South Dakota',
            TN: 'Tennessee',
            TX: 'Texas',
            UT: 'Utah',
            VT: 'Vermont',
            VA: 'Virginia',
            WA: 'Washington',
            WV: 'West Virginia',
            WI: 'Wisconsin',
            WY: 'Wyoming',
        },
        utm: null,
        videoLogging: false,
        dynamicPageRedirectKey: false,
    }),
    getters: {
        utmSource(state): string | null {
            const source: string | null = state.utm?.utm_source ?? null

            if (!source) {
                return null
            }

            const sourceKeys: string[] = Object.keys(utmSourceSchema)

            for (let i = 0; sourceKeys.length > i; i++) {
                const key = sourceKeys[i]
                const sources = utmSourceSchema[key] ?? []

                if (sources.includes(source)) {
                    return key
                }
            }

            return null
        },
    },
    actions: {
        assignClientIp(ip: string | string[] | undefined) {
            if (ip) {
                this.clientIp = ip.toString()
            }
        },
        openFormSuccessModal(message: string | null = null) {
            this.formSuccessModalOpen = true
            this.formSuccessModalMessage = message
        },
        closeFormSuccessModal() {
            this.formSuccessModalOpen = false

            // lets the modal hold it's space until it's closed
            setTimeout(() => (this.formSuccessModalMessage = null), 500)
        },
        enableVideoLogging() {
            this.videoLogging = true
        },
        disableVideoLogging(removeCookie: boolean = false) {
            this.videoLogging = false

            if (removeCookie) {
                this.removeVideoLoggingCookie()
            }
        },
        removeVideoLoggingCookie() {
            const videoLogCookie: CookieRef<string | undefined | null> =
                useCookie('video_logging')

            videoLogCookie.value = null
        },
        buildStatesForForms() {
            const returnArr: Record<string, any>[] = [
                { value: null, label: 'Select state', disabled: true },
            ]

            Object.keys(this.states).forEach((key: string) => {
                returnArr.push({
                    value: key,
                    label: this.states[key],
                })
            })

            return returnArr
        },
    },
})

interface State {
    formSuccessModalOpen: boolean
    formSuccessModalMessage: string | null
    clientIp: string | null
    states: Record<string, string>
    utm: Record<string, string> | null
    videoLogging: boolean
    dynamicPageRedirectKey: boolean
}
