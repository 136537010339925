import { defineStore } from 'pinia'
import { useFetchAutoznetwork } from '~/composables/fetch/useFetchAutoznetwork'
import { useInventoryStore } from '~/stores/inventory'
import type {
    AutoCompleteModification,
    AutoCompleteResult,
} from '~/utils/types/search'
import type { ResourceMeta } from '~/utils/types/global'

export const useSearchStore = defineStore('search', {
    state: (): State => ({
        isOpen: false,
        isLoadingSearch: false,
        searchQuery: '',
        searchResults: null,
        autoCompleteQuery: '',
        autoCompleteResults: null,
        autoCompleteTyping: false,
        searchMode: 'standard',
    }),
    getters: {
        getIsOpen(state) {
            return state.isOpen
        },
        getIsLoadingSearch(state) {
            return state.isLoadingSearch
        },
        getAutoCompleteIsTyping(state) {
            return state.autoCompleteTyping
        },
        getSearchQuery(state) {
            return state.searchQuery
        },
        getSearchResults(state) {
            return state.searchResults ? state.searchResults : null
        },
        getSearchMode(state) {
            return state.searchMode
        },
        getIsAiMode(state) {
            return state.searchMode === 'ai'
        },
        autoComplete(state): AutoCompleteData {
            return {
                results: state.autoCompleteResults?.results ?? null,
                meta: state.autoCompleteResults?.meta ?? {},
                modification: state.autoCompleteResults?.modification ?? null,
                noQueryResults:
                    state.autoCompleteResults?.no_query_results ?? [],
                queryId: state.autoCompleteResults?.query_id ?? null,
            }
        },
    },
    actions: {
        setSearchMode(mode: 'standard' | 'ai') {
            this.searchMode = mode
        },
        toggleSearchMode() {
            this.searchMode = this.searchMode === 'ai' ? 'standard' : 'ai'
        },
        setAutoCompleteIsTyping() {
            if (!this.autoCompleteTyping) {
                this.autoCompleteTyping = true
            }
        },
        setAutoCompleteIsNotTyping() {
            if (this.autoCompleteTyping) {
                this.autoCompleteTyping = false
            }
        },
        openSearch() {
            this.isOpen = true
        },
        closeSearch() {
            this.isOpen = false
        },
        clearSearchResults() {
            this.searchQuery = ''
            this.isLoadingSearch = false
            this.searchResults = null
            this.autoCompleteQuery = null
            this.autoCompleteResults = null
        },
        clearAutoCompleteQuery() {
            this.autoCompleteQuery = ''
        },
        async fetchSearchResults(query: string) {
            const visitorStore = useVisitorStore()

            this.isLoadingSearch = true
            this.searchQuery = query

            const params = await visitorStore.addFingerprintToQuery({
                query,
                limit: 10,
            })

            const { data } = await useFetchAutoznetwork(`/inventory/search`, {
                query: useInventoryStore().addDomainQueryParams(params, false),
                fetch: true,
            })

            this.searchResults = data.value
            this.isLoadingSearch = false
        },
        async fetchAutoCompleteResults() {
            const visitorStore = useVisitorStore()

            this.isLoadingSearch = true

            const params = await visitorStore.addFingerprintToQuery({
                query: this.autoCompleteQuery,
                limit: 20,
            })

            const { data } = await useFetchAutoznetwork(
                `/inventory/search/autocomplete`,
                {
                    query: useInventoryStore().addDomainQueryParams(
                        params,
                        false,
                    ),
                    fetch: true,
                },
            )

            this.autoCompleteResults = data.value
            this.isLoadingSearch = false
        },
    },
})

interface State {
    isOpen: boolean
    isLoadingSearch: boolean
    searchQuery: string
    searchResults: Record<string, any> | null
    autoCompleteQuery: string | null
    autoCompleteResults: Record<string, any> | null
    autoCompleteTyping: boolean
    searchMode: 'standard' | 'ai'
}

interface AutoCompleteData {
    results: AutoCompleteResult[] | null
    meta: ResourceMeta
    modification: AutoCompleteModification | null
    noQueryResults: AutoCompleteResult[]
    queryId: string | null
}
